import { Controller } from "@hotwired/stimulus"
import { Modal } from "bootstrap"

export default class extends Controller {

  connect() {

    let backdrop = document.querySelector(".modal-backdrop")
    if (backdrop) { backdrop.remove() }

    this.modal = new Modal(this.element)

    this.element.addEventListener('hidden.bs.modal', () => {
      this.element.remove()
    })

    this.element.addEventListener('shown.bs.modal', () => {
      let firstField = this.element.querySelectorAll(
        'form.simple_form:not([data-skip-focus="true"])[class*="new_"] input.form-control, form.simple_form:not([data-skip-focus="true"])[class*="new_"] select'
      )[0]
      if (firstField) {
        if (firstField.tomselect) {
          firstField.tomselect.focus()
        } else {
          firstField.focus()
          if (firstField instanceof HTMLInputElement) { firstField.select() }
        }
      }
      let explicitField = this.element.querySelector('.explicit-focus')
      if (explicitField) {
        explicitField.focus()
      }
    })

    this.modal.show()
  }

  close() {
    this.modal.hide()
  }

}
